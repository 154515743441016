import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router";
import clsx from "clsx";

import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import CheckCrossIcons from "../../HOC/FunctionComponents/CheckCrossIcons";

export const batchFields = [
  "id",
  { barcodes: ["id", "code"] },
  {
    customer: [
      "code",
      "name",
      {
        operation: `isMobileHidden(code: WAYBILL)`,
        fields: [],
        variables: {
        },
      },
      {
        zone: ["id", "code", "name"]
      },
      {
        subzone: ["id", "code", "name"]
      },
      "phone",
      "mobile"
    ],
  },
];

export const shipmentFields = [
  {
    data: [
      "code",
      "recipientName",
      "recipientAddress",
      "recipientMobile",
      "recipientPhone",
      "description",
      "weight",
      "piecesCount",
      "totalAmount",
      "senderName",
      "senderPhone",
      "senderMobile",
      "refNumber",
      "notes",
      "date",
      {
        type: ["name", "code"],
      },
      {
        originBranch: ["name"],
      },
      {
        customer: [
          "code",
          {
            operation: `isMobileHidden(code: WAYBILL)`,
            fields: [],
            variables: {
            },
          },
        ],
      },
      {
        openable: ["code"],
      },

      {
        recipientZone: ["name"],
      },
      {
        recipientSubzone: ["name"],
      },
      {
        senderZone: ["name"],
      },
      {
        senderSubzone: ["name"],
      },
    ],
  },
];

const PREFIX = "SHIPMENT_STICKY_PRINT";

export const classes = {
  hideSenderPhone: `${PREFIX}-hideSenderPhone`,
  printSticky: `${PREFIX}-printSticky`,
  flexBetween: `${PREFIX}-flexBetween`,
  flex: `${PREFIX}-flex`,
  stickyCode: `${PREFIX}-stickyCode`,
  customHeight16: `${PREFIX}-customHeight16`,
  customHeight12: `${PREFIX}-customHeight12`,
  customHeight5: `${PREFIX}-customHeight5`,
  customHeight6: `${PREFIX}-customHeight6`,
  customHeight13: `${PREFIX}-customHeight13`,
  customHeight8: `${PREFIX}-customHeight8`,
  bold: `${PREFIX}-bold`,
  paddingX: `${PREFIX}-paddingX`,
  tableHeader: `${PREFIX}-tableHeader`,
  borderRadius: `${PREFIX}-borderRadius`,
  fs12: `${PREFIX}-fs12`,
  borderRight: `${PREFIX}-borderRight`,
  borderBottom: `${PREFIX}-borderBottom`,
  width50: `${PREFIX}-width50`,
  tableCell: `${PREFIX}-tableCell`,
  tableCellAddress: `${PREFIX}-tableCellAddress`,
  flexChild: `${PREFIX}-flexChild`,
  details: `${PREFIX}-details`,
  width20: `${PREFIX}-width20`,
  widthInherit: `${PREFIX}-widthInherit`,
  borderLeft: `${PREFIX}-borderLeft`,
};

export const Root = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  [`& .${classes.hideSenderPhone}`]: {
    display: "none !important",
  },
  [`& .${classes.printSticky}`]: {
    // border: "1px solid #000",
    pageBreakAfter: "always",
    position: "relative",
    pageBreakInside: "avoid",
    width: "17.5cm",
    height: "10cm",
    maxHeight: "10cm",
    overflow: "hidden",
    fontSize: 11,
    color: "black",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "0.8cm",
  },

  [`& .${classes.flexBetween}`]: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
  },
  [`& .${classes.flex}`]: {
    display: 'flex',
    alignItems: "center",
  },

  [`& .${classes.stickyCode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    // height: "100px",
    overflow: "hidden",
    fontSize: 50,
  },

  [`& .${classes.customHeight16}`]: {
    height: "16%",
  },
  [`& .${classes.customHeight12}`]: {
    height: "12%"
  },
  [`& .${classes.customHeight5}`]: {
    height: "5%"
  },
  [`& .${classes.customHeight6}`]: {
    height: "6%"
  },
  [`& .${classes.customHeight13}`]: {
    height: "13%"
  },
  [`& .${classes.customHeight8}`]: {
    height: "8%"
  },

  [`& .${classes.bold}`]: {
    fontWeight: 700,
  },

  [`& .${classes.paddingX}`]: {
    padding: theme.spacing(0, 1),
  },

  [`& .${classes.borderRadius}`]: {
    borderRadius: "10px",
    border: "1px solid black",
  },

  [`& .${classes.tableHeader}`]: {
    backgroundColor: "#ddd",
    padding: theme.spacing(0.5, 2),
    marginBottom: theme.spacing(1)
  },

  [`& .${classes.fs12}`]: {
    fontSize: "12px"
  },
  [`& .${classes.borderRight}`]: {
    borderRight: "1px solid black",
    textTransform: "capitalize",
    height: "100%",
  },
  [`& .${classes.borderLeft}`]: {
    borderLeft: "1px solid black",
    textTransform: "capitalize",
    height: "100%",
  },
  [`& .${classes.borderBottom}`]: {
    borderBottom: "1px solid black",
  },
  [`& .${classes.width50}`]: {
    width: "50%",
  },
  [`& .${classes.width20}`]: {
    width: "70px",
    minWidth: "70px",
    // maxWidth: "77px",
  },

  [`& .${classes.tableCell}`]: {
    display: "flex",
    padding: '0 6px',
    // border: "1px solid black",
    // borderBlockEnd: "none",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
  },

  [`& .${classes.tableCellAddress}`]: {
    display: "flex",
    padding: '0 6px',
    // border: "1px solid black",
    // borderBlockEnd: "none",
    overflow: "hidden",
    // flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.flexChild}`]: {
    display: "flex",
    flex: "1 1 auto",
    minHeight: 0,
  },
  [`& .${classes.details}`]: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    minHeight: 0,
  },
  [`& .${classes.widthInherit}`]: {
    width: "inherit",
    // whiteSpace: "pre-line",
    color: "black",
    fontSize: 11,
    margin: 0,
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box !important"
  },
}));

function createDynamicStyles() {
  const style = document.createElement('style');
  style.type = 'text/css';

  const styles = `
  @page {
      size: 0 !important;
      margin: 1mm !important;
      overflow: hidden;
  }
  * {
          color: black;
  }
  body {
          background: #fff !important
  }
  `;

  if (style.styleSheet) {
    style.styleSheet.cssText = styles;
  } else {
    style.appendChild(document.createTextNode(styles));
  }

  document.head.appendChild(style);
}

const ShipmentStickyPrint = (props) => {
  const {
    loading,
    validData,
    settingsLoad,
    parsedData,
    isBatch,
    customer,
    currency,
  } = props

  const { t } = useTranslation()

  const getSenderMobiles = (shipment) => {
    return customer ? customer?.mobile && customer?.phone
      ? `${customer?.mobile} - ${customer?.phone}`
      : customer?.mobile ??
      customer?.phone :
      shipment?.senderMobile && shipment?.senderPhone
        ? `${shipment?.senderMobile} - ${shipment?.senderPhone}`
        : shipment?.senderMobile ??
        shipment?.senderPhone
  }

  useEffect(() => {
    createDynamicStyles()

  }, [])

  return (
    <Fragment>
      {loading || (!loading && !validData) || settingsLoad ? (
        <>
          <EmptyTableMessage
            loading={loading}
            message={t("noShipmentWithCode")}
          />
        </>
      ) : (
        parsedData.map((shipment, index) => {
          return (
            <Root key={index}>

              <div
                className={clsx({
                  [classes.printSticky]: !loading && validData,
                })}
                style={{ lineHeight: "1.2", paddingLeft: "5.9cm", paddingRight: "0.5cm" }}
              >
                <div style={{
                  minHeight: "2.5cm",
                  width: "17.5cm",
                  paddingRight: "7cm",
                  paddingTop: "1cm",
                  paddingLeft: "5.9cm",
                  textAlign: "center",
                  fontSize: "17px",
                  paddingBottom: "0.3cm",
                  display: "flex",
                  alignItems: "center",

                }}>
                  {shipment.originBranch.name}
                </div>
                <div style={{ justifyContent: "center" }} className={clsx(
                  classes.flex,
                  classes.paddingX,
                  classes.stickyCode,
                )}>
                  *{shipment.code}*
                </div>

                <Box className={clsx(
                  classes.tableHeader,
                  classes.borderRadius,
                  classes.flexBetween,
                )}>
                  <Typography className={classes.fs12}>
                    {shipment?.type?.name}
                  </Typography>
                  <Box className={classes.flex}>
                    <Typography className={classes.fs12} sx={{ mr: 1 }}>
                      {t("packageOpen")}
                    </Typography>
                    <div
                      style={{ alignItems: "end", fontSize: 10 }}
                    >
                      {!isBatch && (
                        <CheckCrossIcons
                          sx={{ fontSize: "18px !important" }}
                          active={shipment?.openable?.code === "Y"}
                        />
                      )}
                    </div>
                  </Box>
                  <Box className={classes.flex}>
                    <Typography className={classes.fs12} sx={{ mr: 1 }}>
                      {t("pieces")} :
                    </Typography>
                    <div>
                      {shipment?.piecesCount}
                    </div>
                  </Box>
                </Box>
                <Box className={clsx(
                  classes.borderRadius,
                  classes.details,
                )}>
                  <div className={clsx(classes.borderBottom, classes.flexChild)}>
                    <div className={clsx(classes.width50, classes.flex)}>
                      <div className={clsx(classes.borderRight, classes.tableCell, classes.width20)}>
                        {t("senderName")}
                      </div>
                      <div className={clsx(classes.tableCell, classes.widthInherit)}>
                        {shipment?.senderName}
                      </div>
                    </div>
                    <div className={clsx(classes.width50, classes.flex)}>
                      <div className={clsx(classes.borderRight, classes.borderLeft, classes.tableCell, classes.width20)}>
                        {t("senderPhone")}
                      </div>
                      <div className={clsx(classes.tableCell, classes.widthInherit)}>
                        {getSenderMobiles(shipment)}
                      </div>
                    </div>
                  </div>
                  <div className={clsx(classes.flexChild, classes.customHeight13, classes.borderBottom,)}>
                    <div className={classes.flex} style={{ width: "100%" }}>
                      <div className={clsx(classes.borderRight, classes.tableCell, classes.width20)}>
                        {t("description")}
                      </div>
                      <div className={clsx(classes.tableCell, classes.widthInherit)}>
                        {shipment?.description}
                      </div>
                    </div>
                  </div>
                  <div className={clsx(classes.borderBottom, classes.flexChild)}>
                    <div className={clsx(classes.width50, classes.flex)}>
                      <div className={clsx(classes.borderRight, classes.tableCell, classes.width20)}>
                        {t("recipient")}
                      </div>
                      <div className={clsx(classes.tableCell, classes.widthInherit)}>
                        {shipment?.recipientName}
                      </div>
                    </div>
                    <div className={clsx(classes.width50, classes.flex)}>
                      <div className={clsx(classes.borderRight, classes.borderLeft, classes.tableCell, classes.width20)}>
                        {t("recipientPhone")}
                      </div>
                      <div className={clsx(classes.tableCell, classes.widthInherit)}>
                        {shipment?.recipientMobile && shipment?.recipientPhone
                          ? `${shipment?.recipientMobile} - ${shipment?.recipientPhone}`
                          : shipment?.recipientMobile ??
                          shipment?.recipientPhone}
                      </div>
                    </div>
                  </div>
                  <div className={clsx(classes.borderBottom, classes.flexChild)}>
                    <div className={clsx(classes.width50, classes.flex)}>
                      <div className={clsx(classes.borderRight, classes.tableCell, classes.width20)}>
                        {t("total")}
                      </div>
                      <div className={clsx(classes.tableCell, classes.widthInherit)}>
                        {shipment?.totalAmount + " " + currency}
                      </div>
                    </div>
                    <div className={clsx(classes.width50, classes.flex)}>
                      <div className={clsx(classes.borderRight, classes.borderLeft, classes.tableCell, classes.width20)}>
                        {t("recipientZone")}
                      </div>
                      <div className={clsx(classes.tableCell, classes.widthInherit)}>
                        {shipment?.recipientZone
                          ? shipment?.recipientZone?.name +
                          " - " +
                          shipment?.recipientSubzone?.name
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className={clsx(classes.flexChild)}>
                    <div className={classes.flex} style={{ width: "100%" }}>
                      <div className={clsx(classes.borderRight, classes.tableCell, classes.width20)}>
                        {t("notes")}
                      </div>
                      <div className={clsx(classes.tableCell, classes.widthInherit)}>
                        {shipment?.notes}
                      </div>
                    </div>
                  </div>

                </Box>
              </div>
            </Root>
          )
        })
      )}
    </Fragment>
  );
};

export default withRouter(ShipmentStickyPrint);
